.order_img {
  width: 1rem;
  display: block;
  border-radius: 0.05rem;
  height: 0.8rem;
}

.cell_box {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.pay_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  height: 0.5rem;
  z-index: 100;
  padding-left: 0.2rem;
}

.btn_pay {
  background: #ed424a;
  color: #fff;
  width: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}